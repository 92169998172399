import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { isUseCase } from '@/lib/utils'

import { timeRangeOptions } from './-utils/date.utils'

const defaultDateRange = {
  startDate: dayjs().subtract(3, 'hours').toISOString(),
  endDate: dayjs().toISOString(),
}
const defaultTimeRange = '3h'

export const SearchSchema = z.object({
  sites: z.array(z.string()).optional(),
  buildings: z.array(z.string()).optional(),
  chartHistory: z.union([z.literal('roomTemp'), z.literal('heatingCircuits'), z.literal('ecs'), z.literal('production')]).optional(),
  siteHistory: z.string().optional(),
  buildingHistory: z.string().optional(),
  floorsHistory: z.array(z.string()).optional(),
  spacesHistory: z.array(z.string()).optional(),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  timeRange: z.enum(timeRangeOptions).default(defaultTimeRange),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/energy-monitoring/heating/')({
  validateSearch: SearchSchema,
  async beforeLoad() {
    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
