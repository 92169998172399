import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { SaveIcon } from 'lucide-react'
import * as React from 'react'

import { cn } from '@/lib/utils'

import Loader from './loader'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90 font-semibold leading-6',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ButtonProps = {
  asChild?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, type = 'button', size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'

  return <Comp ref={ref} className={cn(buttonVariants({ variant, size, className }))} type={type} {...props} />
})
Button.displayName = 'Button'

const SaveButton = React.forwardRef<HTMLButtonElement, ButtonProps & { isLoading?: boolean }>(
  ({ children, className, variant, type = 'button', size, asChild = false, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    const childrenWithIcon = (
      <>
        {isLoading ? <Loader className="size-5" fill="#ffffff" /> : <SaveIcon className="size-5" />}
        {children}
      </>
    )

    return (
      <Comp ref={ref} className={cn('space-x-4', buttonVariants({ variant, size, className }))} type={type} {...props}>
        {childrenWithIcon}
      </Comp>
    )
  },
)
SaveButton.displayName = 'SaveButton'

export { Button, SaveButton, buttonVariants }
